import Vue from 'vue'
import Router from 'vue-router'
import StoreManager from '@/store/store_manager'
import VueGuard from '@/assets/js/vue_guard'
import { operationNotification } from '@/assets/js/notification'
import { store } from './store/index'

Vue.use(Router)

let router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import('./views/Home.vue'),
    },
    {
      path: '/about',
      name: 'About',
      component: () => import('./views/About.vue'),
    },
    {
      path: '/log_in',
      name: 'Login',
      component: () => import('./views/LogIn.vue'),
    },
    {
      path: '/register',
      name: 'Register',
      component: () => import('./views/Register.vue'),
    },
    {
      path: '/reset_password',
      name: 'Reset Password',
      component: () => import('./views/ResetPassword.vue'),
    },
    {
      path: '/features',
      name: 'Features',
      component: () => import('./views/Features.vue'),
    },
    {
      path: '/terms_of_service',
      name: 'terms_of_service',
      component: () => import('./views/TermsOfService.vue'),
    },
    {
      path: '/privacy_policy',
      name: 'privacy policy',
      component: () => import('./views/PrivacyPolicy.vue'),
    },
    {
      path: '/welcome',
      name: 'Welcome',
      meta: {
        guarded: false,
      },
      component: () => import('./views/Welcome.vue'),
    },
    {
      path: '/school/:school_id',
      name: 'School',
      meta: {
        guarded: true, //authentication required to access this resource
      },
      component: () => import('./views/School/App.vue'),
      children: [
        {
          path: 'edit',
          name: 'Edit School',
          component: () => import('./views/School/School.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'school_info',
          name: 'School Info',
          component: () => import('./views/School/SchoolInfo.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'account',
          name: 'Account',
          component: () => import('./views/Account.vue'),
          meta: {
            authorized_roles: [
              'host',
              'admin',
              'educator',
              'parent',
              'student',
            ],
          },
        },
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: () => import('./views/Dashboard.vue'),
          meta: {
            authorized_roles: [
              'host',
              'admin',
              'educator',
              'parent',
              'student',
            ],
          },
        },
        {
          path: 'subscriptions',
          name: 'Subscriptions',
          component: () => import('./views/Subscription/Subscriptions.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'subscription_renewal',
          name: 'Subscription Renewal',
          component: () =>
            import('./views/Subscription/SubscriptionRenewal.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'subscription_payment',
          name: 'Subscription Payment',
          component: () =>
            import('./views/Subscription/SubscriptionPayment.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'terms',
          name: 'Terms',
          component: () => import('./views/Term/Terms.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'term/:id',
          name: 'Term',
          component: () => import('./views/Term/Term.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'term_info',
          name: 'Term Info',
          component: () => import('./views/Term/TermInfo.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'subjects',
          name: 'Subjects',
          component: () => import('./views/Subject/Subjects.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'subject/:id',
          name: 'Subject',
          component: () => import('./views/Subject/Subject.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'subject_info',
          name: 'Subject Info',
          component: () => import('./views/Subject/SubjectInfo.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'class_groups',
          name: 'Class Groups',
          component: () => import('./views/ClassGroup/ClassGroups.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'class_group_hierarchy',
          name: 'Class Groups Hierarchy',
          component: () => import('./views/ClassGroup/ClassGroupHierarchy.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'class_group/:id',
          name: 'Class Group',
          component: () => import('./views/ClassGroup/ClassGroup.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'class_group_info/:id',
          name: 'Class Group Info',
          component: () => import('./views/ClassGroup/ClassGroupInfo.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'school_classes',
          name: 'school classes',
          component: () => import('./views/SchoolClass/SchoolClasses.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'school_class/:id',
          name: 'school_class',
          component: () => import('./views/SchoolClass/SchoolClass.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'school_class/:id/promote',
          name: 'school class promotion',
          component: () => import('./views/SchoolClass/Promote.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'school_class/:id/master_list',
          name: 'master list',
          component: () => import('./views/SchoolClass/MasterList.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'school_class/:id/bulk_result_email',
          name: 'bulk result email',
          component: () => import('./views/SchoolClass/BulkResultEmail.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'school_class_info/:id',
          name: 'school class info',
          component: () => import('./views/SchoolClass/SchoolClassInfo.vue'),
          meta: {
            authorized_roles: ['host', 'admin', 'educator'],
          },
        },
        {
          path: 'school_class_hierarchy',
          name: 'school class hierarchy',
          component: () =>
            import('./views/SchoolClass/SchoolClassHierarchy.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'school_class/:id/results',
          name: 'school class results',
          component: () => import('./views/SchoolClass/Results.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'assigned_subjects',
          name: 'assigned subjects',
          component: () =>
            import('./views/AssignedSubject/AssignedSubjects.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'withdrawals',
          name: 'withdrawals',
          component: () => import('./views/Withdrawal/Withdrawals.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'graduates',
          name: 'graduates',
          component: () => import('./views/Graduate/Graduates.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'graduate/:id',
          name: 'graduate',
          component: () => import('./views/Graduate/Graduate.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'graduates_info/:term_id',
          name: 'graduates info',
          component: () => import('./views/Graduate/GraduatesInfo.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'graduates/:term_id/graduate_info/:id',
          name: 'graduate info',
          component: () => import('./views/Graduate/GraduateInfo.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'graduate_class_groups',
          name: 'graduate class groups',
          component: () =>
            import('./views/GraduateClassGroup/GraduateClassGroups.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'graduate_class_group/:id',
          name: 'graduate class group',
          component: () =>
            import('./views/GraduateClassGroup/GraduateClassGroup.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'graduate_class_group_info/:id',
          name: 'graduate class group info',
          component: () =>
            import('./views/GraduateClassGroup/GraduateClassGroupInfo.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'assigned_subject/:id',
          name: 'assigned subject',
          component: () =>
            import('./views/AssignedSubject/AssignedSubject.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'assigned_subject_info/:id',
          name: 'assigned subject info',
          component: () =>
            import('./views/AssignedSubject/AssignedSubjectInfo.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'max_score/:id',
          name: 'Max Score',
          component: () => import('./views/MaxScore/MaxScore.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'max_score_info/:id',
          name: 'Max Score Info',
          component: () => import('./views/MaxScore/MaxScoreInfo.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'ates_policies',
          name: 'Ates Policies',
          component: () => import('./views/AtesPolicy/AtesPolicies.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'ates_policy/:id',
          name: 'Ates Policy',
          component: () => import('./views/AtesPolicy/AtesPolicy.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'ates_policy_info/:id',
          name: 'Ates Policy Info',
          component: () => import('./views/AtesPolicy/AtesPolicyInfo.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'grading_scales',
          name: 'Grading Scales',
          component: () => import('./views/GradingScale/GradingScales.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'grading_scale/:id',
          name: 'Grading Scale',
          component: () => import('./views/GradingScale/GradingScale.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'admins',
          name: 'admins',
          component: () => import('./views/Admin/Admins.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'admin/:id',
          name: 'admin',
          component: () => import('./views/Admin/Admin.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'admin_info/:id',
          name: 'admin_info',
          component: () => import('./views/Admin/AdminInfo.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'educators',
          name: 'educators',
          component: () => import('./views/Educator/Educators.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'educator/:id',
          name: 'educator',
          component: () => import('./views/Educator/Educator.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'educator_info/:id',
          name: 'educator info',
          component: () => import('./views/Educator/EducatorInfo.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'students_in_school',
          name: 'Students In School',
          component: () => import('./views/Student/StudentsInSchool.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'students/:school_class_id',
          name: 'Students',
          component: () => import('./views/Student/Students.vue'),
          meta: {
            authorized_roles: ['host', 'admin', 'educator'],
          },
        },
        {
          path: 'student/:id',
          name: 'Student',
          component: () => import('./views/Student/Student.vue'),
          meta: {
            authorized_roles: ['host', 'admin', 'educator'],
          },
        },
        {
          path: 'student/:id/withdraw',
          name: 'Withdraw',
          component: () => import('./views/Student/Withdraw.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'student/:id/graduate',
          name: 'Graduate Student',
          component: () => import('./views/Student/Graduate.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'student/:id/transfer',
          name: 'transfer',
          component: () => import('./views/Student/Transfer.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'school_fee_payment/:student_id',
          name: 'School Fee Payment',
          component: () => import('./views/Student/SchoolFeePayment.vue'),
          meta: {
            authorized_roles: ['host', 'admin', 'parent'],
          },
        },
        {
          path: 'school_class/:school_class_id/student_info/:id',
          name: 'Student Info',
          component: () => import('./views/Student/StudentInfo.vue'),
          meta: {
            authorized_roles: ['host', 'admin', 'educator', 'parent'],
          },
        },
        {
          path: 'school_fee_detail/:id',
          name: 'school fee detail',
          component: () => import('./views/Student/SchoolFeeDetail.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'student_search',
          name: 'Student Search',
          component: () => import('./views/Student/Search.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'bulk_upload',
          name: 'Students Bulk Upload',
          component: () => import('./views/Student/BulkUpload.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'parents',
          name: 'parents',
          component: () => import('./views/Parent/Parents.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'parent/:id',
          name: 'parent',
          component: () => import('./views/Parent/Parent.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'parent_info/:id',
          name: 'parent info',
          component: () => import('./views/Parent/ParentInfo.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'parents_bulk_upload',
          name: 'Parents Bulk Upload',
          component: () => import('./views/Parent/BulkUpload.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'add_students/:id',
          name: 'add students',
          component: () => import('./views/Parent/AddStudents.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'parents_students/:id',
          name: 'parents students',
          component: () => import('./views/Parent/ParentsStudents.vue'),
          meta: {
            authorized_roles: ['host', 'admin', 'parent'],
          },
        },
        {
          path: 'assigned_classes',
          name: 'assigned classes',
          component: () => import('./views/AssignedClass/AssignedClasses.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'assigned_class/:id',
          name: 'assigned class',
          component: () => import('./views/AssignedClass/AssignedClass.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'assigned_class_info/:id',
          name: 'assigned class info',
          component: () =>
            import('./views/AssignedClass/AssignedClassInfo.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'ates/:id/school_class/:school_class_id',
          name: 'Ates',
          component: () => import('./views/Ate/Ates.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'new_ate',
          name: 'New Ate',
          component: () => import('./views/Ate/NewAte.vue'),
          meta: {
            authorized_roles: ['educator'],
          },
        },
        {
          path: 'ate_unmarked',
          name: 'Ate Unmarked',
          component: () => import('./views/Ate/AteUnmarked.vue'),
          meta: {
            authorized_roles: ['educator'],
          },
        },
        {
          path: 'ate_scores/:id',
          name: 'Ate Scores',
          component: () => import('./views/Ate/AteScores.vue'),
          meta: {
            authorized_roles: ['educator'],
          },
        },
        {
          path: 'ate_assessment',
          name: 'Ate Assessment',
          component: () => import('./views/AteAssessment/Index.vue'),
          meta: {
            authorized_roles: ['host', 'admin', 'educator'],
          },
        },
        {
          path: 'total_scores',
          name: 'Total Scores',
          component: () => import('./views/AteAssessment/TotalScores.vue'),
          meta: {
            authorized_roles: ['host', 'admin', 'educator'],
          },
        },
        {
          path: 'average_scores',
          name: 'Average Scores',
          component: () => import('./views/AteAssessment/AverageScores.vue'),
          meta: {
            authorized_roles: ['host', 'admin', 'educator'],
          },
        },
        {
          path: 'highest_subject_scores',
          name: 'Highest Subject Scores',
          component: () =>
            import('./views/AteAssessment/HighestSubjectScores.vue'),
          meta: {
            authorized_roles: ['host', 'admin', 'educator'],
          },
        },
        {
          path: 'cumulative_scores',
          name: 'Cumulative Scores',
          component: () => import('./views/AteAssessment/CumulativeScores.vue'),
          meta: {
            authorized_roles: ['host', 'admin', 'educator'],
          },
        },
        {
          path: 'cumulative_termly_total',
          name: 'Cumulative Termly Total',
          component: () =>
            import('./views/AteAssessment/CumulativeTermlyTotal.vue'),
          meta: {
            authorized_roles: ['host', 'admin', 'educator'],
          },
        },
        {
          path: 'educator_assigned_classes',
          name: 'educator_assigned_classes',
          component: () =>
            import('./views/Educator/EducatorAssignedClasses.vue'),
          meta: {
            authorized_roles: ['educator'],
          },
        },
        {
          path: 'result_class/:school_class_id',
          name: 'result class',
          component: () => import('./views/Result/ResultClass.vue'),
          meta: {
            authorized_roles: ['host', 'admin', 'educator'],
          },
        },
        {
          path:
            'school_class/:school_class_id/result_info/:student_id/term_id/:term_id',
          name: 'result info',
          component: () => import('./views/Result/ResultInfo.vue'),
          meta: {
            authorized_roles: [
              'host',
              'admin',
              'educator',
              'student',
              'parent',
            ],
          },
        },
        {
          path: 'result_comments',
          name: 'result comments',
          component: () => import('./views/Comment/ResultComments.vue'),
          meta: {
            authorized_roles: ['host', 'admin', 'educator'],
          },
        },
        {
          path: 'previous_result_comments/:student_id/term_id/:term_id',
          name: 'previous result comments',
          component: () => import('./views/Comment/PreviousResultComments.vue'),
          meta: {
            authorized_roles: ['host', 'admin', 'educator'],
          },
        },
        {
          path: 'comments',
          name: 'comments',
          component: () => import('./views/Comment/Comments.vue'),
          meta: {
            authorized_roles: ['host', 'admin', 'educator'],
          },
        },
        {
          path: 'comment/:id',
          name: 'comment',
          component: () => import('./views/Comment/Comment.vue'),
          meta: {
            authorized_roles: ['host', 'admin', 'educator'],
          },
        },
        {
          path: 'fast_comments',
          name: 'fast comments',
          component: () => import('./views/FastComment/FastComments.vue'),
          meta: {
            authorized_roles: ['host', 'admin', 'educator'],
          },
        },
        {
          path: 'fast_comment/:id',
          name: 'fast comment',
          component: () => import('./views/FastComment/FastComment.vue'),
          meta: {
            authorized_roles: ['host', 'admin', 'educator'],
          },
        },
        {
          path: 'fast_comment_info/:id',
          name: 'fast comment info',
          component: () => import('./views/FastComment/FastCommentInfo.vue'),
          meta: {
            authorized_roles: ['host', 'admin', 'educator'],
          },
        },
        {
          path: 'result_psychomotors',
          name: 'result psychomotors',
          component: () => import('./views/Psychomotor/ResultPsychomotors.vue'),
          meta: {
            authorized_roles: ['host', 'admin', 'educator'],
          },
        },
        {
          path: 'previous_result_psychomotors/:student_id/term_id/:term_id',
          name: 'previous_result psychomotors',
          component: () =>
            import('./views/Psychomotor/PreviousResultPsychomotors.vue'),
          meta: {
            authorized_roles: ['host', 'admin', 'educator'],
          },
        },
        {
          path: 'psychomotors',
          name: 'psychomotors',
          component: () => import('./views/Psychomotor/Psychomotors.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'psychomotor/:id',
          name: 'psychomotor',
          component: () => import('./views/Psychomotor/Psychomotor.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'fast_psychomotors',
          name: 'fast psychomotors',
          component: () => import('./views/Psychomotor/FastPsychomotors.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'g_courses/:id',
          name: 'g_courses',
          component: () => import('./views/GCourse/GCourses.vue'),
          meta: {
            authorized_roles: ['host', 'admin', 'educator', 'student'],
          },
        },
        {
          path: 'new_g_course',
          name: 'new_g_course',
          component: () => import('./views/GCourse/NewGCourse.vue'),
          meta: {
            authorized_roles: ['educator'],
          },
        },
        {
          path:
            'g_courses/:school_class_id/school_class/:school_class/subject/:subject/g_course_info/:course_id',
          name: 'g_course_info',
          component: () => import('./views/GCourse/GCourseInfo.vue'),
          meta: {
            authorized_roles: ['host', 'admin', 'educator', 'student'],
          },
        },
        {
          path: 'e_courses',
          name: 'e_courses',
          component: () => import('./views/ECourse/ECourses.vue'),
          meta: {
            authorized_roles: ['host', 'admin', 'educator', 'student'],
          },
        },
        {
          path: 'e_course/:id',
          name: 'e_course',
          component: () => import('./views/ECourse/ECourse.vue'),
          meta: {
            authorized_roles: ['host', 'admin', 'educator'],
          },
        },
        {
          path: 'e_course_info/:id',
          name: 'e_course_info',
          component: () => import('./views/ECourse/ECourseInfo.vue'),
          meta: {
            authorized_roles: ['host', 'admin', 'educator'],
          },
        },
        {
          path: 'e_course_work/:id/questions',
          name: 'questions',
          component: () => import('./views/Question/Questions.vue'),
          meta: {
            authorized_roles: ['host', 'admin', 'educator'],
          },
        },
        {
          path: 'e_course_work/:id/multiple_choice',
          name: 'Multiple Choice Questions',
          component: () =>
            import('./views/ECourseWork/MultipleChoice/Questions.vue'),
          meta: {
            authorized_roles: ['host', 'admin', 'educator'],
          },
        },
        {
          path: 'e_course_work/:id/questions_uploader',
          name: 'questions_uploader',
          component: () => import('./views/Question/QuestionsUploader.vue'),
          meta: {
            authorized_roles: ['host', 'admin', 'educator'],
          },
        },
        {
          path: 'e_course_works/:e_course_topic_id',
          name: 'e_course_works',
          component: () => import('./views/ECourseWork/ECourseWorks.vue'),
          meta: {
            authorized_roles: ['host', 'admin', 'educator'],
          },
        },
        {
          path: 'e_course_work/:id',
          name: 'e_course_work',
          component: () => import('./views/ECourseWork/ECourseWork.vue'),
          meta: {
            authorized_roles: ['host', 'admin', 'educator'],
          },
        },
        {
          path: 'student/:student_id/e_course_solution/:e_course_work_id',
          name: 'e_course_solution',
          component: () =>
            import('./views/ECourseSolution/ECourseSolution.vue'),
          meta: {
            authorized_roles: ['host', 'admin', 'educator', 'student'],
          },
        },
        {
          path: 'student/:student_id/e_course_solution_info/:e_course_work_id',
          name: 'e_course_solution_info',
          component: () =>
            import('./views/ECourseSolution/ECourseSolutionInfo.vue'),
          meta: {
            authorized_roles: ['host', 'admin', 'educator', 'student'],
          },
        },
        {
          path: 'fee_management',
          name: 'Fee Management',
          component: () => import('./views/FeeManagement/Index.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'school_fees_reports',
          name: 'School Fees Reports',
          component: () => import('./views/FeeManagement/Reports.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'school_fees_report',
          name: 'School Fees Report',
          component: () =>
            import('./views/FeeManagement/Report/SchoolFeesReport.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'school_fees_class_group_report',
          name: 'School Fees Class Group Report',
          component: () =>
            import(
              './views/FeeManagement/Report/SchoolFeesClassGroupReport.vue'
            ),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'school_fees_school_class_report/:class_group_id',
          name: 'School Fees School Class Report',
          component: () =>
            import(
              './views/FeeManagement/Report/SchoolFeesSchoolClassReport.vue'
            ),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path:
            'school_fees_students_report/class_group/:class_group_id/school_class/:school_class_id',
          name: 'School Fees Students Report',
          component: () =>
            import('./views/FeeManagement/Report/SchoolFeesStudentsReport.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'fee_items',
          name: 'Fee Items',
          component: () => import('./views/FeeManagement/FeeItem/FeeItems.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'fee_item/:id',
          name: 'Fee Item',
          component: () => import('./views/FeeManagement/FeeItem/FeeItem.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'fee_templates',
          name: 'Fee Templates',
          component: () =>
            import('./views/FeeManagement/FeeTemplate/FeeTemplates.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'fee_template_info/:id',
          name: 'Fee Template Info',
          component: () =>
            import('./views/FeeManagement/FeeTemplate/FeeTemplateInfo.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'transport_routes',
          name: 'Transport Routes',
          component: () => import('./views/TransportRoute/TransportRoutes.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'transport_route/:id',
          name: 'Transport Route',
          component: () => import('./views/TransportRoute/TransportRoute.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'books',
          name: 'Books',
          component: () => import('./views/Book/Books.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'book/:id',
          name: 'Book',
          component: () => import('./views/Book/Book.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'added_services',
          name: 'Added Services',
          component: () => import('./views/AddedService/AddedServices.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'added_service/:id',
          name: 'Added Service',
          component: () => import('./views/AddedService/AddedService.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'clothings',
          name: 'Clothings',
          component: () => import('./views/Clothing/Clothings.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'clothing/:id',
          name: 'Clothing',
          component: () => import('./views/Clothing/Clothing.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'school_fees',
          name: 'School Fees',
          component: () => import('./views/SchoolFee/SchoolFees.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'school_fee/:id',
          name: 'School Fee',
          component: () => import('./views/SchoolFee/SchoolFee.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'student/:student_id/school_fee/:id',
          name: 'School Fee',
          component: () => import('./views/SchoolFee/PaySchoolFee.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'school_fee_info/:school_class_id',
          name: 'School Fee Info',
          component: () => import('./views/SchoolFee/SchoolFeeInfo.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'user/:id',
          name: 'User',
          component: () => import('./views/FirebaseAuth/User.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'attendances',
          name: 'Attendances',
          component: () => import('./views/Attendance/Attendances.vue'),
          meta: {
            authorized_roles: ['host', 'admin', 'educator'],
          },
        },
        {
          path: 'attendance/:id',
          name: 'Attendance',
          component: () => import('./views/Attendance/Attendance.vue'),
          meta: {
            authorized_roles: ['host', 'admin', 'educator'],
          },
        },
        {
          path: 'attendance_info/:id',
          name: 'Attendance Info',
          component: () => import('./views/Attendance/AttendanceInfo.vue'),
          meta: {
            authorized_roles: ['host', 'admin', 'educator'],
          },
        },
        {
          path: 'fast_attendance',
          name: 'Fast Attendance',
          component: () => import('./views/Attendance/FastAttendance.vue'),
          meta: {
            authorized_roles: ['host', 'admin', 'educator'],
          },
        },
        {
          path: 'previous_attendance/:student_id/term_id/:term_id',
          name: 'previous_attendance',
          component: () => import('./views/Attendance/PreviousAttendance.vue'),
          meta: {
            authorized_roles: ['host', 'admin', 'educator'],
          },
        },
        {
          path: 'school_class_attendance_info/:id/month/:month',
          name: 'School Class Attendance Info',
          component: () =>
            import('./views/Attendance/SchoolClassAttendanceInfo.vue'),
          meta: {
            authorized_roles: ['host', 'admin', 'educator'],
          },
        },
        {
          path:
            'student_attendance_info/:id/school_class/:school_class_id/month/:month',
          name: 'Student Attendance Info',
          component: () =>
            import('./views/Attendance/StudentAttendanceInfo.vue'),
          meta: {
            authorized_roles: ['host', 'admin', 'educator'],
          },
        },
        {
          path: 'students_pdf',
          name: 'Students Pdf',
          component: () => import('./views/Student/StudentsPdf.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'school_class_students_pdf/:id',
          name: 'School Class Students Pdf',
          component: () => import('./views/Student/SchoolClassStudentsPdf.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
        {
          path: 'pdf_report',
          name: 'Pdf Report',
          component: () => import('./views/Reports/PdfReport.vue'),
          meta: {
            authorized_roles: ['host', 'admin'],
          },
        },
      ],
    },
  ],
})

function setLoggedIn() {
  store.commit('setLoading', false)
  store.commit('setLoggedIn', false)
}

router.beforeEach((to, from, next) => {
  const isProtected = to.matched.some(
    (path) => path.parent && path.parent.name === 'School'
  )

  // When session params is empty user is logged out
  if (sessionStorage.length === 0) {
    next()
  } else {
    // When user is logged in and navigates to home it should redirect to dashboard
    if (to.fullPath === '/') {
      next({ name: 'Dashboard' })
    } else if (isProtected) {
      const user = JSON.parse(StoreManager.load('user'))
      const billing = JSON.parse(StoreManager.load('billing'))
      // const term = JSON.parse(StoreManager.load('term'))

      const loginTime = user.login_time * 1000
      const expiryTime = loginTime + 30 * 60 * 1000

      // Check school
      if (parseInt(to.params.school_id) !== user.school_id) {
        setLoggedIn()
        StoreManager.clear_all()
        next({ name: 'Login' })
        operationNotification(
          'You are attempting to access resource with invalid school ID.',
          'warning'
        )
        // Check subscription
      } else if (billing.expired || billing.status === 'Pending') {
        if (
          to.name !== 'Dashboard' &&
          to.name !== 'Subscriptions' &&
          to.name !== 'Subscription Payment' &&
          to.name !== 'Subscription Renewal' &&
          to.name !== 'Students In School' &&
          to.name !== 'Students' &&
          to.name !== 'Student' &&
          to.name !== 'Student Info' &&
          to.name !== 'Withdraw'
        ) {
          next({ name: 'Subscription Renewal' })
        } else {
          next()
        }
        // Check session expiration
      } else if (expiryTime < new Date().getTime()) {
        setLoggedIn()
        StoreManager.clear_all()
        next({ name: 'Login' })
        operationNotification(
          'Your session has expired. Login again to continue.',
          'warning'
        )
        // Check authorization
      } else if (!VueGuard.authorize(user, to, router)) {
        next({ name: 'Dashboard' })
        operationNotification(
          'You are attempting to access resource without the right authorization.',
          'warning'
        )
      } else {
        next()
      }
    } else {
      next()
    }
  }
})

export default router
