const devSettings = {
  apiKey: 'AIzaSyC7Kg57JvITexAhl2-hZt0s5oVx6fdPNls',
  authDomain: 'simsed-unity-dev.firebaseapp.com',
  databaseURL: 'https://simsed-unity-dev.firebaseio.com',
  projectId: 'simsed-unity-dev',
  storageBucket: 'simsed-unity-dev.appspot.com',
  messagingSenderId: '68149000660',
}

const prodSettings = {
  apiKey: 'AIzaSyDaw6q98EXOWKguC-7GjJkvFlF6QrvFVVY',
  authDomain: 'simsed-unity.firebaseapp.com',
  databaseURL: 'https://simsed-unity.firebaseio.com',
  projectId: 'simsed-unity',
  storageBucket: 'simsed-unity.appspot.com',
  messagingSenderId: '1075861937666',
}

export function config() {
  const host = window.location.host
  if (
    host == 'localhost:8082' ||
    host == 'localhost:8083' ||
    host == 'simsed-unity-dev.firebaseapp.com' ||
    host == 'simsed-unity-dev.web.app' ||
    host == '127.0.0.1:8887'
  ) {
    return devSettings
  } else if (host == 'simsed.com') {
    return prodSettings
  }
}
