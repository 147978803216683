import CryptoJs from 'crypto-js'

export function encrypt(data) {
  // If it is not a string  or object
  // throw error
  if (typeof data === 'object') {
    data = JSON.stringify(data)
  }
  return CryptoJs.AES.encrypt(data, 'simsed').toString()
}

export function decrypt(data_from_store) {
  // If it is not a string  or object
  // throw error
  const decrypted_data = CryptoJs.AES.decrypt(data_from_store, 'simsed')

  return decrypted_data.toString(CryptoJs.enc.Utf8)
}

export const StoreManager = {
  options: {
    color: 'white',
    thickness: '2px',
  },
  save: (key, value) => {
    sessionStorage.setItem(key, encrypt(value))
  },
  load: (key) => {
    if (sessionStorage.length != 0) {
      return decrypt(sessionStorage.getItem(key))
    } else {
      return null
    }
  },
  clear: (key) => {
    sessionStorage.removeItem(key)
  },
  clear_all: () => {
    if (sessionStorage.length != 0) {
      sessionStorage.clear()
    }
  },
}

export default StoreManager
