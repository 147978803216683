<template>
  <router-link
    :to="route"
    class="nav-menu-item"
    @click.native="$emit('close-menu')"
  >
    <p class="icon">
      <i :class="icon" size="lg"></i>
    </p>
    <p class="heading">{{ name }}</p>
  </router-link>
</template>

<script>
export default {
  name: 'NavMenuItem',
  props: {
    name: String,
    icon: String,
    route: String,
  },
}
</script>
