import { getToolbar } from '../../src/components/vue-editor/config/toolbar.js'
import { getConfig } from '../../src/components/vue-editor/config/index'

let toolbar = {}
let { btns, selects } = getToolbar()
let config = getConfig('toolbar')

config.forEach((name) => {
  if (name !== 'divider' && name !== '|') {
    toolbar[name] = {}
    if (name in selects) {
      toolbar[name].value = ''
      toolbar[name].showPopup = false
    } else {
      if (btns[name] && btns[name].action === undefined) {
        toolbar[name].showPopup = false
      }
    }
    toolbar[name].status = '' // default disabled actived
  }
})

export const state = {
  appName: 'Simsed',
  pageTitle: 'Dashboard',
  loggedIn: false,
  subMenus: [],
  showParent: true,
  isAppModalActive: false,
  functionsUrl: '',
  pdfWeaverUrl: '',
  loading: false,
  progressBarValue: 0,
  records: [],
  record: {},
  value: null,
  graduate: {},
  term: {},
  billing: {},
  modalId: null,
  fatherAuth: false,
  motherAuth: false,
  displayUpdateButton: false,
  view: 'design',
  content: '',
  toolbar,
  fullscreen: false,
  rect: {
    left: 0,
    top: 0,
    width: 0,
    height: 0,
  },
  command: {
    name: '',
    value: '',
  },
  callee: {
    name: '',
    params: '',
  },
}
