import VueCookies from 'vue-cookies'

const shared = {
  beforeRouteEnter(to, from, next) {
    next((_vm) => {
      if (
        to.name !== 'Login' &&
        to.name !== 'Home' &&
        to.name !== 'Features' &&
        to.name !== 'Register' &&
        to.name !== 'Dashboard'
      ) {
        VueCookies.set('lastPath', to.fullPath, 60 * 30)
      }
    })
  },
}

export default shared
