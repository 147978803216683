<template>
  <div class="columns">
    <div class="column has-text-centered">
      <fa-icon icon="spinner" class="fa-spin fa-5x" style="color: orange" />
      <br />
      <p class="subtitle">
        <slot>Loading...</slot>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'loading',
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
div.columns {
  height: 300px;
}
p.subtitle {
  margin-top: 30px;
}
</style>
