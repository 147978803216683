var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:("buttons " + _vm.position)},[_c('a',{directives:[{name:"show",rawName:"v-show",value:(_vm.showInfo),expression:"showInfo"}],staticClass:"is-small has-text-info",style:({
      fontSize: _vm.fontSize + 'em',
      marginLeft: '0px',
    }),on:{"click":function($event){return _vm.$emit('initialize-info')}}},[_c('fa-icon',{attrs:{"icon":"info-circle","size":"lg"}})],1),_c('a',{directives:[{name:"show",rawName:"v-show",value:(_vm.showUpdate),expression:"showUpdate"}],staticClass:"is-small has-text-success",style:({
      fontSize: _vm.fontSize + 'em',
      marginLeft: _vm.editMarginLeft + 'px',
    }),on:{"click":function($event){return _vm.$emit('initialize-update')}}},[_c('fa-icon',{attrs:{"icon":"edit","size":"lg"}})],1),_c('a',{directives:[{name:"show",rawName:"v-show",value:(_vm.showRemove),expression:"showRemove"}],staticClass:"is-small has-text-danger",style:({
      fontSize: _vm.fontSize + 'em',
      marginLeft: _vm.deleteMarginLeft + 'px',
    }),on:{"click":function($event){return _vm.$emit('initialize-remove')}}},[_c('fa-icon',{attrs:{"icon":"trash-alt","size":"lg"}})],1),_vm._t("default",null,{"student":_vm.record})],2)}
var staticRenderFns = []

export { render, staticRenderFns }