import { NotificationProgrammatic as Notification } from 'buefy'

export function operationNotification(message, status = 'success') {
  Notification.open({
    duration: 5000,
    message: message,
    position: 'is-top',
    type: `is-${status}`,
    hasIcon: true,
  })
}

export function crudNotification(errors, message) {
  return new Promise((resolve, reject) => {
    if (errors.length == 0) {
      operationNotification(message)
      resolve('Success!')
    } else {
      operationNotification(errors[0], 'danger')
      reject('Error encountered!')
    }
  })
}
