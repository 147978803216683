import axios from 'axios'
import qs from 'qs'
import StoreManager from '@/store/store_manager'
import { getFunctions, httpsCallable } from 'firebase/functions'

export function deleteUser(uid) {
  const functions = getFunctions()
  const deleteUser = httpsCallable(functions, 'deleteUser')
  deleteUser(uid)
    .then((result) => {
      if (result.data.status == 200) {
        console.log('removed')
      } else if (result.data.status == 400) {
        console.log('error')
      }
    })
    .catch(function (error) {
      console.log(error)
    })
}

export async function initializeApp(token) {
  const url = await fetchAppUrl()

  const response = await axios.post(
    `${url}/log_in`,
    qs.stringify({ token: token }),
    {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
  )

  return response
}

export async function fetchLoggedIn() {
  return await StoreManager.load('loggedIn')
}

export async function fetchToken() {
  return await StoreManager.load('token')
}

export async function fetchUser() {
  return await JSON.parse(StoreManager.load('user'))
}

export async function fetchSchool() {
  return await JSON.parse(StoreManager.load('school'))
}

export async function fetchSchoolEmail() {
  return await JSON.parse(StoreManager.load('school')).email
}

export async function fetchTerm() {
  return await JSON.parse(StoreManager.load('term'))
}

export async function fetchBilling() {
  return await JSON.parse(StoreManager.load('billing'))
}

function url() {
  if (window.location.host == 'localhost:8082') {
    return 'http://localhost:3000'
  } else {
    return 'https://simsed-server-r.herokuapp.com'
  }
}

export async function fetchAppUrl() {
  return url()
}
