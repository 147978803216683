<template>
  <div id="sd-footer" class="primary">
    <!-- %footer.mt-5 -->
    <div class="columns is-multiline is-mobile">
      <div class="column is-12 site-name has-text-centered">SIMSED</div>
      <div class="column is-12 has-text-centered">
        <ul class="footer-list">
          <li>
            <router-link to="/">Home</router-link>
          </li>
          <li>
            <router-link to="/features">Features</router-link>
          </li>
          <li>
            <router-link to="/register">Register</router-link>
          </li>
          <li>
            <router-link to="/faq">Faq</router-link>
          </li>
        </ul>
      </div>
      <div class="column is-6 has-text-right">
        <a href="https://web.facebook.com/simsedunity">
          <img src="../assets/images/facebook-logo.png" alt="facebook-logo" />
        </a>
      </div>
      <div class="column is-6">
        <a href="https://web.facebook.com/simsedunity">
          <img src="../assets/images/twitter-logo.png" alt="twitter-logo" />
        </a>
      </div>
      <div class="column is-12 has-text-centered added-info">
        <a href="https://pidasys.com">© 2020 PIDATAS SYSTEMS</a>
      </div>
      <div class="column is-12 has-text-right added-info">
        <ul class="footer-list">
          <li>
            <router-link to="/terms_of_service">Terms of Service</router-link>
          </li>
          <li>.</li>
          <li>
            <router-link to="/privacy_policy">Privacy Policy</router-link>
          </li>
          <li>.</li>
          <li>
            <router-link to="/affiliates">Affiliates</router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
#sd-footer {
  padding-top: 36px;
  padding-bottom: 80px;
  height: auto;
}

#sd-footer a {
  color: #000;
}

.added-info {
  font-size: 0.75em;
}

ul.footer-list {
  text-align: center;
}

ul.footer-list li {
  display: inline-block;
  padding: 8px;
}
</style>
