import {
  required,
  confirmed,
  length,
  email,
  double,
  min_value,
  max_value,
  max,
  min,
} from 'vee-validate/dist/rules'
import { extend } from 'vee-validate'

extend('required', {
  ...required,
  message: 'This field is required',
})

extend('email', {
  ...email,
  message: 'This field must be a valid email',
})

extend('confirmed', {
  ...confirmed,
  message: 'This field confirmation does not match',
})

extend('length', {
  ...length,
  params: ['length'],
  message: 'This field must not be more than {length}',
})

extend('double', {
  ...double,
  params: ['decimals'],
  message: 'This field must be two decimal places',
})

extend('min_value', {
  ...min_value,
  params: ['min'],
  message: 'The field must be greater than {min}.',
})

extend('max_value', {
  ...max_value,
  params: ['max'],
  message: 'The field must be less than {max}.',
})

extend('max', {
  ...max,
  params: ['length'],
  message: 'The field must be less than {length}.',
})

extend('min', {
  ...min,
  params: ['length'],
  message: 'The field must be greater than or equal {length}.',
})

extend('regex', {
  validate(value, args) {
    return /234\d{10}$/.test(value)
  },
  params: ['pattern'],
  message: 'Phone is invalid. Use 234... format',
})

extend('compare', {
  validate(value, args) {
    return value !== args.email
  },
  params: ['email', 'role'],
  message: "Email is invalid. The email must be different from {role}'s.",
})
