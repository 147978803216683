export const mutations = {
  setPageTitle(state, payload) {
    state.pageTitle = payload
  },
  setLoggedIn(state, payload) {
    state.loggedIn = payload
  },
  setSubMenus(state, payload) {
    state.subMenus = payload
  },
  setShowParent(state, payload) {
    state.showParent = payload
  },
  setAppModalActive(state, payload) {
    state.isAppModalActive = payload
  },

  setFunctionsUrl(state, payload) {
    state.functionsUrl = payload
  },
  setPdfWeaverUrl(state, payload) {
    state.pdfWeaverUrl = payload
  },
  setLoading(state, payload) {
    state.loading = payload
  },
  setRecords(state, payload) {
    state.records = payload
  },
  setRecord(state, payload) {
    state.record = payload
  },
  setValue(state, payload) {
    state.value = payload
  },
  setGraduate(state, payload) {
    state.graduate = payload
  },
  setTerm(state, payload) {
    state.term = payload
  },
  setBilling(state, payload) {
    state.billing = payload
  },

  setModalId(state, payload) {
    state.modalId = payload
  },

  setDisplayUpdateButton(state, payload) {
    state.displayUpdateButton = payload
  },

  UPDATE_SELECT_VALUE({ toolbar }, { name, value }) {
    toolbar[name].value = value
  },

  UPDATE_BUTTON_STATES({ toolbar }, data) {
    for (let name in data) {
      if (toolbar[name]) {
        toolbar[name].status = data[name]
      }
    }
  },

  UPDATE_POPUP_DISPLAY({ toolbar }, data) {
    for (let name in toolbar) {
      if (toolbar[name].showPopup === undefined) continue
      if (data && data.name === name) {
        toolbar[name].showPopup = data.display
      } else if (toolbar[name].showPopup !== false) {
        toolbar[name].showPopup = false
      }
    }
  },

  UPDATE_RECT(state, data) {
    state.rect = data
  },

  UPDATE_CONTENT(state, data) {
    state.content = data
  },

  SWITCH_VIEW(state, data) {
    state.view = data
  },

  SET_FULL_SCREEN(state, data) {
    state.fullscreen = data
  },

  CALL_METHOD(state, data) {
    state.callee = data
  },

  EXEC_COMMAND(state, data) {
    state.command = data
  },
}
