var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal",attrs:{"id":("modal-" + _vm.id)}},[_c('div',{staticClass:"modal-background",attrs:{"id":("modal-background-" + _vm.id)}}),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
return [_c('div',{staticClass:"modal-card",style:(_vm.expanded ? _vm.modalContainer : _vm.normalScreen(_vm.width))},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[_vm._v(" "+_vm._s(_vm.modalTitle)+" - "),_c('span',{staticClass:"is-capitalized"},[_vm._v(_vm._s(_vm.context))])]),_c('button',{staticClass:"delete",attrs:{"id":("modal-close-" + _vm.id),"aria-label":"close"}})]),_c('section',{staticClass:"modal-card-body"},[(
            _vm.context === 'add' ||
            _vm.context === 'create' ||
            _vm.context === 'update' ||
            _vm.context === 'activate' ||
            _vm.context === 'restore' ||
            _vm.context === 'submit'
          )?_vm._t("add",function(){return [_c('p',[_vm._v("You are about to perform a create operation.")]),_c('br'),_c('p',[_vm._v("Would you want to proceed?")])]}):_vm._e(),(_vm.context === 'remove' || _vm.context === 'reset')?_vm._t("remove",function(){return [_c('p',[_vm._v("You are about to perform a remove operation.")]),_c('p',[_vm._v("This cannot be reversed.")]),_c('br'),_c('p',[_vm._v("Would you want to proceed?")])]}):_vm._e()],2),_c('footer',{staticClass:"modal-card-foot"},[_c('div',{staticClass:"buttons is-pulled-right"},[_c('button',{staticClass:"button cancel",attrs:{"id":("modal-cancel-" + _vm.id)}},[_vm._v(" Cancel ")]),_c('button',{staticClass:"button is-primary is-capitalized",on:{"click":function($event){return _vm.$emit(_vm.context, validate)}}},[_vm._v(" "+_vm._s(_vm.context)+" ")])])])])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }