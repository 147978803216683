import gql from 'graphql-tag'
import { crudNotification } from '@/assets/js/notification'

const UserAuth = {
  data() {
    return {
      userAuth: false,
      userId: null,
      selectedUser: {},
    }
  },
  watch: {
    userAuth(value) {
      this.userId = this.selectedUser.id

      if (value && !this.isActivated()) {
        this.context = 'activate'
        this.openModal()
      } else if (!value && this.isActivated()) {
        this.context = 'reset'
        this.openModal()
      }
    },
  },
  methods: {
    isActivated() {
      return this.selectedUser.firebaseUserId.length > 10
    },
    activate() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation ActivateUserAuth($id: Int!) {
              activateUser(input: { id: $id }) {
                user {
                  id
                }
                errors
              }
            }
          `,
          variables: {
            id: parseInt(this.userId),
          },
        })
        .then((response) => {
          crudNotification(
            response.data.activateUser.errors,
            'Successfully activated.'
          ).then(() => {
            this.closeModal()
          })
        })
        .catch((error) => {
          console.error(error)
        })
    },
    reset() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation ResetUserAuth($id: Int!) {
              resetUser(input: { id: $id }) {
                user {
                  id
                }
                errors
              }
            }
          `,
          variables: {
            id: parseInt(this.userId),
          },
        })
        .then((response) => {
          crudNotification(
            response.data.resetUser.errors,
            'Successfully reset.'
          ).then(() => {
            this.closeModal()
          })
        })
        .catch((error) => {
          console.error(error)
        })
    },
  },
}

export default UserAuth
