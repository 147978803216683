<template>
  <div class="private">
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
}
</script>
