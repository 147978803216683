<template>
  <div :id="`modal-${id}`" class="modal">
    <div :id="`modal-background-${id}`" class="modal-background"></div>
    <ValidationObserver ref="observer" v-slot="{ validate }">
      <div
        class="modal-card"
        :style="expanded ? modalContainer : normalScreen(width)"
      >
        <header class="modal-card-head">
          <p class="modal-card-title">
            {{ modalTitle }} -
            <span class="is-capitalized">{{ context }}</span>
          </p>
          <button
            :id="`modal-close-${id}`"
            class="delete"
            aria-label="close"
          ></button>
        </header>
        <section class="modal-card-body">
          <slot
            name="add"
            v-if="
              context === 'add' ||
              context === 'create' ||
              context === 'update' ||
              context === 'activate' ||
              context === 'restore' ||
              context === 'submit'
            "
          >
            <p>You are about to perform a create operation.</p>
            <br />
            <p>Would you want to proceed?</p>
          </slot>
          <slot
            name="remove"
            v-if="context === 'remove' || context === 'reset'"
          >
            <p>You are about to perform a remove operation.</p>
            <p>This cannot be reversed.</p>
            <br />
            <p>Would you want to proceed?</p>
          </slot>
        </section>
        <footer class="modal-card-foot">
          <div class="buttons is-pulled-right">
            <button :id="`modal-cancel-${id}`" class="button cancel">
              Cancel
            </button>
            <button
              class="button is-primary is-capitalized"
              @click="$emit(context, validate)"
            >
              {{ context }}
            </button>
          </div>
        </footer>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
const randomstring = require('randomstring')

export default {
  name: 'modal',
  data() {
    return {
      modalContainer: {
        // marginTop: "0px",
        // marginRight: "auto",
        // marginBottom: "0px",
        // marginLeft: "auto",
        maxHeight: '100vh',
        height: '100vh',
        width: 'calc(100vw - 16px)',
      },
    }
  },
  props: {
    id: String,
    modalTitle: { type: String, default: 'Modal' },
    context: String,
    expanded: { type: Boolean, default: false },
    width: { type: String, default: '400px' },
    options: {
      type: Object,
      default: () => {
        return { show_new_record: false }
      },
    },
    showModal: { type: Boolean, default: false },
  },
  watch: {
    showModal(value) {
      const modal = document.getElementById(`modal-${this.id}`)
      if (value) {
        modal.classList.toggle('is-active')
      } else {
        modal.classList.remove('is-active')
      }
    },
  },
  mounted() {
    ;[
      document.getElementById(`modal-background-${this.id}`),
      document.getElementById(`modal-close-${this.id}`),
      document.getElementById(`modal-cancel-${this.id}`),
    ].forEach((el) => {
      el.addEventListener('click', () => {
        this.$emit('update:showModal', false)
        this.$root.$emit('closedModal')
      })
    })
  },
  methods: {
    normalScreen(width) {
      this.modalContainer.width = width
      delete this.modalContainer.height
      delete this.modalContainer.maxHeight

      return this.modalContainer
    },
  },
  components: {
    ValidationObserver,
  },
}
</script>

<style lang="scss" scoped></style>
