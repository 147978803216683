<template>
  <div id="sd-public">
    <nav class="navbar is-fixed-top">
      <div id="navbar-main-container">
        <div class="navbar-brand">
          <!-- Nav Bar content -->
          <router-link class="navbar-item" to="/">
            <h1 class="site-name">{{ site.name }}</h1>
          </router-link>
        </div>
        <div class="navbar-menu-toggler is-hidden-tablet">
          <button
            id="hamburger-btn"
            :class="`hamburger ${hamburgerType} navbar-item`"
            type="button"
            aria-label="Menu"
            aria-controls="navigation"
            @click="toggleMenu()"
          >
            <span class="hamburger-box">
              <span class="hamburger-inner"></span>
            </span>
          </button>
        </div>
      </div>
      <div id="navbar-menu-container">
        <nav class="level">
          <div
            v-for="loggedOutItem in loggedOutItems"
            :key="loggedOutItem.name"
            class="level-item has-text-centered"
            style="width: 80px"
          >
            <NavMenuItems
              :name="loggedOutItem.name"
              :icon="loggedOutItem.icon"
              :route="loggedOutItem.route"
              @close-menu="closeMenu()"
            />
          </div>
        </nav>
      </div>
    </nav>
    <sd-body />
    <sd-footer />
  </div>
</template>

<script>
import NavMenuItems from '@/components/NavMenuItem'
import SdBody from './SdBody'
import SdFooter from './SdFooter'

export default {
  name: 'home',
  data() {
    return {
      hamburgerType: 'hamburger--spin',
      site: { name: 'SIMSED' },
      loggedOutItems: [
        { name: 'Home', icon: 'fas fa-home', route: '/' },
        { name: 'Features', icon: 'fas fa-chart-pie', route: '/features' },
        { name: 'Register', icon: 'fas fa-university', route: '/register' },
        { name: 'Log In', icon: 'fas fa-sign-in-alt', route: '/log_in' },
      ],
      showMenu: {
        display: 'none',
      },
    }
  },
  components: {
    NavMenuItems,
    SdBody,
    SdFooter,
  },
  methods: {
    toggleMenu() {
      const hamburgerBtn = document.getElementById('hamburger-btn')
      hamburgerBtn.classList.toggle('is-active')
      if (hamburgerBtn.classList.contains('is-active')) {
        document.getElementById('navbar-menu-container').style.display = 'flex'
      } else {
        document.getElementById('navbar-menu-container').style.display = 'none'
      }
    },
    closeMenu() {
      if (window.innerWidth > 768) return
      document.getElementById('hamburger-btn').click()
    },
  },
  mounted() {
    window.addEventListener('resize', () => {
      if (window.innerWidth > 768) {
        document.getElementById('navbar-menu-container').style.display = 'flex'
      } else {
        document.getElementById('navbar-menu-container').style.display = 'none'
      }
    })
  },
}
</script>

<style lang="scss">
.site-name {
  padding: 11px;
  font-family: $primary-font;
  font-size: 1.5em;
  color: #fff;
}

.navbar {
  opacity: 0.8;
  background: linear-gradient(to right, $primary-dark, rgb(255, 255, 255));
  position: relative;
  animation: animateleft 0.6s;
  display: flex;
  flex-wrap: wrap;
}

#navbar-main-container {
  display: flex;
  flex: 1 100%;
}

.navbar-menu-toggler {
  display: flex;
  flex: auto;
  justify-content: flex-end;
}

#navbar-menu-container {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.hero-content {
  min-height: 500px;
}

@media only screen and (min-width: 768px) {
  #navbar-main-container {
    display: flex;
    flex: 1;
  }
}

@media only screen and (max-width: 768px) {
  #navbar-menu-container {
    display: none;
    justify-content: center;
  }

  .navbar {
    opacity: 1;
    background: linear-gradient(to right, $primary-dark, rgb(255, 255, 255));
  }
}

.hero.is-medium .hero-body {
  padding-bottom: 9rem !important;
  padding-top: 9rem !important;
}
</style>
