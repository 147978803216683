import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'

// HTTP connection to the API
const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: url(),
})

// Cache implementation
const cache = new InMemoryCache()

// Create the apollo client
export const apolloClient = new ApolloClient({
  link: httpLink,
  cache,
})

export function url() {
  if (
    window.location.host == 'localhost:8082' ||
    window.location.host == 'localhost:8083'
  ) {
    return 'http://localhost:3000/graphql'
  } else {
    if (window.location.host == 'simsed.com') {
      return 'https://simsed-server-r.herokuapp.com/graphql'
    }
  }
}
