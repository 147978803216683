<template>
  <div :class="`buttons ${position}`">
    <a
      class="is-small has-text-info"
      @click="$emit('initialize-info')"
      v-bind:style="{
        fontSize: fontSize + 'em',
        marginLeft: '0px',
      }"
      v-show="showInfo"
    >
      <fa-icon icon="info-circle" size="lg" />
    </a>
    <a
      class="is-small has-text-success"
      @click="$emit('initialize-update')"
      v-bind:style="{
        fontSize: fontSize + 'em',
        marginLeft: editMarginLeft + 'px',
      }"
      v-show="showUpdate"
    >
      <fa-icon icon="edit" size="lg" />
    </a>
    <a
      class="is-small has-text-danger"
      @click="$emit('initialize-remove')"
      v-bind:style="{
        fontSize: fontSize + 'em',
        marginLeft: deleteMarginLeft + 'px',
      }"
      v-show="showRemove"
    >
      <fa-icon icon="trash-alt" size="lg" />
    </a>
    <slot v-bind:student="record"></slot>
  </div>
</template>

<script>
export default {
  name: 'action-buttons',
  data() {
    return {
      editMarginLeft: '16',
      deleteMarginLeft: '16',
    }
  },
  props: {
    record: {
      type: Object,
      default: () => {
        return {}
      },
    },
    showInfo: {
      type: Boolean,
      default: true,
    },
    showUpdate: {
      type: Boolean,
      default: true,
    },
    showRemove: {
      type: Boolean,
      default: true,
    },
    fontSize: { type: String, default: '1.1' },
    position: { type: String, default: 'is-left' },
  },
  mounted() {
    if (!this.showInfo) {
      this.editMarginLeft = '0'
    }
    if (!this.showInfo && !this.showUpdate) {
      this.deleteMarginLeft = '0'
    }
  },
}
</script>
