/* eslint-disable no-console */

import { register } from 'register-service-worker'
// import { SnackbarProgrammatic as Snackbar } from "buefy";

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    registrationOptions: { scope: './' },
    ready() {
      console.log('Service worker is active.')
    },
    registered() {
      console.log('Service worker has been registered.')
    },
    cached() {
      console.log('Content has been cached for offline use.')
    },
    updatefound() {
      console.log('New content is downloading.')
    },
    updated(registration) {
      console.log('New content is available, please refresh.')
      registration.waiting.postMessage({ type: 'SKIP_WAITING' })
      window.location.reload()
      console.log('Updated')
      // Snackbar.open({
      //   message: "New Version available...",
      //   type: "is-warning",
      //   actionText: "Update",
      //   indefinite: true,
      //   onAction: () => {
      //     registration.waiting.postMessage({ type: "SKIP_WAITING" });
      //     window.location.reload();
      //   },
      // });
    },
    offline() {
      console.log(
        'No internet connection found. App is running in offline mode.'
      )
    },
    error(error) {
      console.error('Error during service worker registration:', error)
    },
  })
}
