<template>
  <div id="orange-bar" class="columns">
    <div class="column">
      <h2 class="subtitle is-5">{{ pageTitle }}</h2>
    </div>
    <div class="column has-text-right">
      <slot name="sub-menu">
        <router-link
          class="sub-menu"
          v-for="(subMenu, index) in subMenus"
          :key="index"
          :to="subMenu.route || '#'"
          @click.native="handleClick(subMenu)"
        >
          <span class="menu-name">{{ subMenu.name }}</span>
          <span
            class="divider"
            v-if="subMenus.length > 0 && index + 1 < subMenus.length"
            >|</span
          >
        </router-link>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'orange-bar',
  props: {
    pageTitle: String,
    subMenus: Array,
  },
  methods: {
    handleClick(menu) {
      if (!menu.action) return
      return menu.action()
    },
  },
}
</script>

<style lang="scss" scoped>
#orange-bar {
  text-align: left;
  background-color: orange;
  padding-left: 24px;
  padding-right: 24px;
}

h2,
h3 {
  color: white;
}

.sub-menu {
  font-size: 0.6em;
  color: white;
  padding-top: 5px;
  text-transform: uppercase;
  font-weight: bold;
}

.menu-name {
  padding: 5px;
}

.menu-name:hover {
  background-color: white;
  border-radius: 10px;
  color: black;
}

.divider {
  padding: 0px 5px 0px 5px;
}
</style>
