import SCHOOL_CLASSES from '../../../graphql/school_class/SchoolClasses.gql'
import ASSIGNED_SCHOOL_CLASSES from '../../../graphql/school_class/AssignedSchoolClasses.gql'

const AppModal = {
  data() {
    return {
      isModalOpen: false,
    }
  },
  mounted() {
    this.$on('closedModal', () => {
      // this.$router.go()
    })
  },
  methods: {
    fetchSchoolClasses() {
      if (this.user.role === 'host' || this.user.role === 'admin') {
        this.$apollo
          .query({
            query: SCHOOL_CLASSES,
            variables: {
              schoolId: this.schoolId,
            },
          })
          .then((response) => {
            this.schoolClasses = response.data.schoolClasses
            this.schoolClass = this.schoolClasses.filter(
              (schoolClass) => schoolClass.id == this.schoolClassId
            )[0].name
          })
          .catch((error) => {
            // console.error(error);
          })
      } else if (this.user.role === 'educator') {
        this.$apollo
          .query({
            query: ASSIGNED_SCHOOL_CLASSES,
            variables: {
              id: this.user.id,
            },
          })
          .then((response) => {
            this.schoolClasses =
              response.data.assignedSchoolClasses.schoolClasses
            this.schoolClass = this.schoolClasses.filter(
              (schoolClass) => schoolClass.id == this.schoolClassId
            )[0].name
          })
          .catch((error) => {
            // console.error(error);
          })
      }
    },
    openModal() {
      this.isModalOpen = true
    },
    closeModal() {
      this.isModalOpen = false
    },
  },
}

export default AppModal
