<template>
  <div id="app">
    <sd-private v-if="$store.getters.getLoggedIn" />
    <sd-public v-else />
  </div>
</template>

<script>
import SdPrivate from '../src/components/SdPrivate'
import SdPublic from '../src/components/SdPublic'
import { fetchLoggedIn } from '../src/assets/js/app_info'

export default {
  name: 'App',
  components: {
    SdPrivate,
    SdPublic,
  },
  mounted() {
    // Ensure that the loggedIn state is preserved even after a page refresh
    fetchLoggedIn().then((loggedIn) => {
      this.$store.commit('setLoggedIn', loggedIn == 'true')
    })
  },
}
</script>

<style lang="scss">
.btn-120 {
  width: 120px;
}

.btn-150 {
  width: 150px;
}

.is-submit {
  background-color: orange !important;
  width: 120px;
}

.is-submit:hover {
  color: #fff !important;
}

.is-text-8 {
  font-size: 0.8em !important;
}

// * {
//   box-sizing: border-box;
// }

a.nav-menu-item:hover {
  color: orange;
}

.menu-list a {
  color: #a8a8a8 !important;
}

.menu-list a:hover {
  background-color: orange !important;
  color: #2c2c2c !important;
}

.primary {
  background-color: $primary;
}

.text-bg-color {
  background-color: #eeeeee;
}

.columns {
  .primary,
  .text-bg-color {
    margin-bottom: 2px;
  }
}
</style>
