export const getters = {
  getAppName(state) {
    return state.app_name
  },
  getPageTitle(state) {
    return state.pageTitle
  },
  getLoggedIn(state) {
    return state.loggedIn
  },
  getSubMenus(state) {
    return state.subMenus
  },
  getShowParent(state) {
    return state.showParent
  },
  getAppModalActive(state) {
    return state.isAppModalActive
  },
  getFunctionsUrl(state) {
    return state.functionsUrl
  },
  getPdfWeaverUrl(state) {
    return state.pdfWeaverUrl
  },
  getLoading(state) {
    return state.loading
  },
  getRecords(state) {
    return state.records
  },
  getRecord(state) {
    return state.record
  },
  getValue(state) {
    return state.value
  },
  getGraduate(state) {
    return state.graduate
  },
  getTerm(state) {
    return state.term
  },
  getBilling(state) {
    return state.billing
  },

  getModalId(state) {
    return state.modalId
  },

  getDisplayUpdateButton(state) {
    return state.displayUpdateButton
  },

  getContent(state) {
    return state.content
  },
}
