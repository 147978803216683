export function guardedRoutes(routes) {
  return routes.filter(
    (route) => route.hasOwnProperty('meta') && route.meta.guarded
  )
}

export function authProtectedRoutes(auth) {
  routes
    .filter((route) => route.hasOwnProperty('meta'))
    .map((route) => route.name)
    .forEach((element) => {
      const name = element.toLowerCase().replace(' ', '_')
      if (!auth.hasOwnProperty(name)) {
        const auth_routes = {}
        auth_routes[name] = {
          list: false,
          create: false,
          update: false,
          remove: false,
        }
        Object.assign(auth, auth_routes)
      }
    })
  return auth
}

export const VueGuard = {
  options: {
    authorized_roles: [],
  },
  // Check if authorized_roles is given via options
  // if it is not check if it is given via meta
  // if it is return false
  authorize: (user, to, router) => {
    if (!user) return
    if (!to.meta.authorized_roles) return
    return to.meta.authorized_roles.includes(user.role)
  },
}

export default VueGuard
