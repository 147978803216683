import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store/index'
import Buefy from 'buefy'
import { apolloClient } from '../graphql/apollo.js'
import './registerServiceWorker'
import VueApollo from 'vue-apollo'
import VueNavs from 'vue-navs'

import Loading from '@/components/Loading'
import OrangeBar from '@/components/OrangeBar'
import AppModal from '@/components/AppModal'
import ActionButtons from '@/components/ActionButtons'
import './vee-validate'

import { config } from './firebaseConfig'
import { getApp, initializeApp } from 'firebase/app'
import { getAuth, connectAuthEmulator } from 'firebase/auth'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'

import '@/assets/css/bulma.scss'
import '@/assets/css/app.css'

import '../src/components/vue-editor/css/style.scss'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import appModal from '@/assets/js/app_modal'
import userAuth from '@/assets/js/user_auth'
import shared from '@/assets/js/shared'

import {
  faTv,
  faHome,
  faChartPie,
  faQuestion,
  faQuestionCircle,
  faUniversity,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt,
  faCog,
  faUsers,
  faFileAlt,
  faCubes,
  faInfo,
  faEdit,
  faUserLock,
  faUserCheck,
  faUserTimes,
  faTrashAlt,
  faSpinner,
  faCalendarDay,
  faAngleLeft,
  faAngleRight,
  faArrowUp,
  faExclamationCircle,
  faExclamationTriangle,
  faCheckCircle,
  faTimesCircle,
  faEye,
  faEyeSlash,
  faUser,
  faInfoCircle,
  faPlusCircle,
  faCommentAlt,
  faTimes,
  faUpload,
  faCheck,
  faDollarSign,
  faArrowDown,
  faCalendar,
  faFilePdf,
  faFileCsv,
  faComments,
  faSmile,
  faPalette,
  faShieldAlt,
} from '@fortawesome/free-solid-svg-icons'

import { faBuffer, faCcAmazonPay } from '@fortawesome/free-brands-svg-icons'

library.add(
  faTv,
  faHome,
  faChartPie,
  faDollarSign,
  faQuestion,
  faQuestionCircle,
  faUniversity,
  faBuffer,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt,
  faCcAmazonPay,
  faCog,
  faUsers,
  faFileAlt,
  faCubes,
  faInfo,
  faEdit,
  faUserLock,
  faUserCheck,
  faUserTimes,
  faTrashAlt,
  faSpinner,
  faCalendarDay,
  faAngleLeft,
  faAngleRight,
  faArrowUp,
  faExclamationCircle,
  faExclamationTriangle,
  faCheckCircle,
  faTimesCircle,
  faEye,
  faEyeSlash,
  faUser,
  faInfoCircle,
  faPlusCircle,
  faCommentAlt,
  faTimes,
  faUpload,
  faCheck,
  faArrowDown,
  faCalendar,
  faFilePdf,
  faFileCsv,
  faComments,
  faSmile,
  faPalette,
  faShieldAlt
)

Vue.use(VueApollo)
Vue.use(Buefy, {
  defaultIconComponent: 'fa-icon',
  defaultIconPack: 'fas',
})

Vue.component('vue-navs', VueNavs)
Vue.component('loading', Loading)
Vue.component('orange-bar', OrangeBar)
Vue.component('app-modal', AppModal)
Vue.component('action-buttons', ActionButtons)
Vue.component('fa-icon', FontAwesomeIcon)

Vue.mixin(appModal)
Vue.mixin(shared)
Vue.mixin(userAuth)

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
})

initializeApp(config())

// if (location.hostname === 'localhost') {
//   const auth = getAuth(initializeApp(config()))
//   connectAuthEmulator(auth, 'http://localhost:9099')

//   const db = getFirestore()
//   connectFirestoreEmulator(db, 'localhost', 8080)

//   const functions = getFunctions(getApp())
//   connectFunctionsEmulator(functions, 'localhost', 5001)
// }

// enablePersistence().catch(function (err) {
//   if (err.code == 'failed-precondition') {
//     // console.log("failed");
//   } else if (err.code == 'unimplemented') {
//     // console.log("enabled");
//   }
// })

Vue.config.productionTip = false

new Vue({
  router,
  store,
  apolloProvider,
  render: (h) => h(App),
}).$mount('#app')
