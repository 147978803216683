import router from '@/router'
import StoreManager from './store_manager'
import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth'
import { initializeApp } from '@/assets/js/app_info.js'
import { operationNotification } from '@/assets/js/notification.js'
import VueCookies from 'vue-cookies'

export const actions = {
  logIn({ commit }, payload) {
    const auth = getAuth()
    signInWithEmailAndPassword(auth, payload.email, payload.password)
      .then(() => {
        auth.currentUser
          .getIdToken(true)
          .then((token) => {
            initializeApp(token)
              .then((response) => {
                // Save to session storage
                StoreManager.save('token', token)
                StoreManager.save('school', response.data.school)
                StoreManager.save('user', response.data.user)
                StoreManager.save('term', response.data.term)
                StoreManager.save('billing', response.data.billing)
                StoreManager.save('loggedIn', 'true')

                let path

                if (
                  VueCookies.get('lastPath') &&
                  parseInt(VueCookies.get('lastPath').split('/')[2]) ===
                    response.data.user.school_id &&
                  parseInt(VueCookies.get('userId')) === response.data.user.id
                ) {
                  path = VueCookies.get('lastPath')
                } else {
                  path = `/school/${response.data.user.school_id}/dashboard`
                }

                router.replace(path)

                VueCookies.set('userId', response.data.user.id)

                commit('setLoading', false)
                commit('setLoggedIn', true)
              })
              .catch((error) => {
                operationNotification(error.message, 'danger')
                commit('setLoading', false)
              })
          })
          .catch((error) => {
            operationNotification(error.message, 'danger')
            commit('setLoading', false)
          })
      })
      .catch((error) => {
        commit('setLoading', false)
        const errorCode = error.code
        const errorMessage = error.message
        // auth / invalid - email
        // Thrown if the email address is not valid.
        //   auth / user - disabled
        // Thrown if the user corresponding to the given email has been disabled.
        //   auth / user - not - found
        // Thrown if there is no user corresponding to the given email.
        //   auth / wrong - password
        // if (errorCode === 'auth/user-not-found') {
        //   alert('Wrong password.');
        // } else {
        //   alert(errorMessage);
        // }
        operationNotification(errorMessage, 'danger')
      })
  },
  logInWithGoogle({ commit }) {
    const provider = new firebase.auth.GoogleAuthProvider()

    const firebaseAuth = firebase.auth()

    firebaseAuth
      .signInWithPopup(provider)
      .then((result) => {
        // // This gives you a Google Access Token. You can use it to access the Google API.
        // const token = result.credential.accessToken;
        // // The signed-in user info.
        // const user = result.user;

        firebaseAuth.currentUser
          .getIdToken(true)
          .then((token) => {
            initializeApp(token).then((response) => {
              // Save to session storage
              StoreManager.save('school', response.data.school)
              StoreManager.save('user', response.data.user)
              StoreManager.save('term', response.data.term)
              StoreManager.save('billing', response.data.billing)
              StoreManager.save('loggedIn', 'true')

              router.replace(
                `/school/${response.data.user.school_id}/dashboard`
              )

              commit('setLoading', false)
              commit('setLoggedIn', true)
            })
          })
          .catch((error) => {
            operationNotification(error.message, 'danger')
            commit('setLoading', false)
          })
      })
      .catch(function (error) {
        // Handle Errors here.
        const errorCode = error.code
        const errorMessage = error.message
        // The email of the user's account used.
        const email = error.email
        // The firebase.auth.AuthCredential type that was used.
        const credential = error.credential
        // ...
      })
  },
  logInWithFacebook({ commit }) {
    const provider = new firebase.auth.FacebookAuthProvider()

    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        // The signed-in user info.
        // var user = result.user;

        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        // const token = result.credential.accessToken;
        firebaseAuth.currentUser
          .getIdToken(true)
          .then((token) => {
            initializeApp(token).then((response) => {
              // Save to session storage
              StoreManager.save('school', response.data.school)
              StoreManager.save('user', response.data.user)
              StoreManager.save('term', response.data.term)
              StoreManager.save('billing', response.data.billing)
              StoreManager.save('loggedIn', 'true')

              router.replace(
                `/school/${response.data.user.school_id}/dashboard`
              )

              commit('setLoading', false)
              commit('setLoggedIn', true)
            })
          })
          .catch((error) => {
            operationNotification(error.message, 'danger')
            commit('setLoading', false)
          })
      })
      .catch((error) => {
        // Handle Errors here.
        var errorCode = error.code
        // console.log(errorCode);
        var errorMessage = error.message
        // console.log(errorMessage);
        // The email of the user's account used.
        var email = error.email
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential
        // ...
      })
  },
  logOut({ commit }) {
    const auth = getAuth()
    signOut(auth)
      .then(() => {
        StoreManager.clear_all()
        router.replace('/log_in')
        commit('setLoading', false)
        commit('setLoggedIn', false)
      })
      .catch((error) => {
        operationNotification(error.message, 'danger')
      })
  },

  updateContent({ commit }, data) {
    commit('UPDATE_CONTENT', data)
  },

  execCommand({ commit }, data) {
    commit('EXEC_COMMAND', data)
  },

  updateSelectValue({ commit }, data) {
    commit('UPDATE_SELECT_VALUE', data)
  },

  updateButtonStates({ commit }, data) {
    commit('UPDATE_BUTTON_STATES', data)
  },

  updatePopupDisplay({ commit }, data) {
    commit('UPDATE_POPUP_DISPLAY', data)
  },

  updateRect({ commit }, data) {
    commit('UPDATE_RECT', data)
  },

  switchView({ commit }, data) {
    commit('SWITCH_VIEW', data)
  },

  setFullScreen({ commit }, data) {
    commit('SET_FULL_SCREEN', data)
  },

  callMethod({ commit }, data) {
    commit('CALL_METHOD', data)
  },
}
